body {
  background-color: #ebe7e3;
  margin-left: 20px;
  margin-right: 20px;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}


@font-face {
    font-family: 'Fedra Serif A Pro Book';
    src: url('/assets/webfonts/FedraSerifAPro-Book.eot');
    src: local('Fedra Serif A Pro Book'), local('FedraSerifAPro-Book'),
        url('/assets/webfonts/FedraSerifAPro-Book.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/FedraSerifAPro-Book.woff2') format('woff2'),
        url('/assets/webfonts/FedraSerifAPro-Book.woff') format('woff'),
        url('/assets/webfonts/FedraSerifAPro-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra Serif A Pro';
    src: url('/assets/webfonts/FedraSerifAPro-Bold.eot');
    src: local('Fedra Serif A Pro Bold'), local('FedraSerifAPro-Bold'),
        url('/assets/webfonts/FedraSerifAPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/FedraSerifAPro-Bold.woff2') format('woff2'),
        url('/assets/webfonts/FedraSerifAPro-Bold.woff') format('woff'),
        url('/assets/webfonts/FedraSerifAPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra Serif A Pro Book';
    src: url('/assets/webfonts/FedraSerifAPro-BookItalic.eot');
    src: local('Fedra Serif A Pro Book Italic'), local('FedraSerifAPro-BookItalic'),
        url('/assets/webfonts/FedraSerifAPro-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/FedraSerifAPro-BookItalic.woff2') format('woff2'),
        url('/assets/webfonts/FedraSerifAPro-BookItalic.woff') format('woff'),
        url('/assets/webfonts/FedraSerifAPro-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra Serif A Pro';
    src: url('/assets/webfonts/FedraSerifAPro-BoldItalic.eot');
    src: local('Fedra Serif A Pro Bold Italic'), local('FedraSerifAPro-BoldItalic'),
        url('/assets/webfonts/FedraSerifAPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/FedraSerifAPro-BoldItalic.woff2') format('woff2'),
        url('/assets/webfonts/FedraSerifAPro-BoldItalic.woff') format('woff'),
        url('/assets/webfonts/FedraSerifAPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra Serif A Pro Normal';
    src: url('/assets/webfonts/FedraSerifAPro-Normal.eot');
    src: local('Fedra Serif A Pro Normal'), local('FedraSerifAPro-Normal'),
        url('/assets/webfonts/FedraSerifAPro-Normal.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/FedraSerifAPro-Normal.woff2') format('woff2'),
        url('/assets/webfonts/FedraSerifAPro-Normal.woff') format('woff'),
        url('/assets/webfonts/FedraSerifAPro-Normal.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra Serif A Pro';
    src: url('/assets/webfonts/FedraSerifAPro-MediumItalic.eot');
    src: local('Fedra Serif A Pro Medium Italic'), local('FedraSerifAPro-MediumItalic'),
        url('/assets/webfonts/FedraSerifAPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/FedraSerifAPro-MediumItalic.woff2') format('woff2'),
        url('/assets/webfonts/FedraSerifAPro-MediumItalic.woff') format('woff'),
        url('/assets/webfonts/FedraSerifAPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra Serif A Pro';
    src: url('/assets/webfonts/FedraSerifAPro-Medium.eot');
    src: local('Fedra Serif A Pro Medium'), local('FedraSerifAPro-Medium'),
        url('/assets/webfonts/FedraSerifAPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/FedraSerifAPro-Medium.woff2') format('woff2'),
        url('/assets/webfonts/FedraSerifAPro-Medium.woff') format('woff'),
        url('/assets/webfonts/FedraSerifAPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra Serif A Pro Normal';
    src: url('/assets/webfonts/FedraSerifAPro-NormalItalic.eot');
    src: local('Fedra Serif A Pro Normal Italic'), local('FedraSerifAPro-NormalItalic'),
        url('/assets/webfonts/FedraSerifAPro-NormalItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/webfonts/FedraSerifAPro-NormalItalic.woff2') format('woff2'),
        url('/assets/webfonts/FedraSerifAPro-NormalItalic.woff') format('woff'),
        url('/assets/webfonts/FedraSerifAPro-NormalItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}




/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/webfonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/webfonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/webfonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/webfonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/webfonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/webfonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/webfonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: url('/assets/webfonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/webfonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/webfonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/webfonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/webfonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/webfonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: url('/assets/webfonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/webfonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/webfonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/webfonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/webfonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/webfonts/roboto-slab-v24-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/webfonts/roboto-slab-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/webfonts/roboto-slab-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/webfonts/roboto-slab-v24-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-500 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/webfonts/roboto-slab-v24-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/webfonts/roboto-slab-v24-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/webfonts/roboto-slab-v24-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/webfonts/roboto-slab-v24-latin-500.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/webfonts/roboto-slab-v24-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/webfonts/roboto-slab-v24-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/webfonts/roboto-slab-v24-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/webfonts/roboto-slab-v24-latin-600.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/webfonts/roboto-slab-v24-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/webfonts/roboto-slab-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/webfonts/roboto-slab-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/webfonts/roboto-slab-v24-latin-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-800 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/webfonts/roboto-slab-v24-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/webfonts/roboto-slab-v24-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/webfonts/roboto-slab-v24-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-800.woff') format('woff'), /* Modern Browsers */
       url('/assets/webfonts/roboto-slab-v24-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/webfonts/roboto-slab-v24-latin-800.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}


hr {
  border: 1px solid #999;
}

h1 {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 2rem;
  font-weight: 700;
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 2.4rem;
  color: #333;
  margin-top: 48px;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  color: #444;
  margin-top: 42px;
  margin-bottom: 12px;
}

h3.text-muted {
  color: #999999;  
}

h4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #444;
  font-size: 1.3rem;
  margin-top: 42px;
}

p {
	font-family: 'Roboto Slab', serif;
  font-weight: 400;
  color: #555555;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

p.lead {
  font-family: 'Roboto Slab', serif;
  font-weight: 500;
  color: #888;
  font-size: 1.5rem;
}

.text-footer {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
}

ul {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  color: #555;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

ol {
  font-family: 'Roboto Slab', serif;
  color: #555;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

ul ol li { padding: 5px 0px; }

li {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  color: #555555;
  font-size: 1.3rem;  
  line-height: 1.8rem;
  padding-bottom: 0.8rem;
}

.text-muted {
  color: #999999;  
}

.current {
  color: #de5a44 !important;
}

.quote {
  margin-left: 40px;
  font-style: italic;
  margin-top: 40px
}

.quote-org {
  margin-left: 40px;
  margin-bottom: 40px
}

.navbar-brand {

}

a {
  text-transform: none;
  color: #555555;
  text-underline-offset: 0.25em !important;
}

a:hover {
  text-transform: none;
  color: #333333;
}

a.site-link-red {
  color: #555555;
  text-decoration: none;
  border-bottom: 3px solid #e78b7c;
}

a.site-link-red:hover {
  color: #de5a44 !important;
  border-bottom: 3px solid #de5a44;
}

a.nav-link-red {
  font-family: 'Roboto', sans-serif;
  color: #de5a44 !important;
  text-decoration: none;
}

a.nav-link-red:hover {
  font-family: 'Roboto', sans-serif;
  color: #c7513d !important;
  text-decoration: none;
}

a.site-link-gray {
  color: #888;
  text-decoration: none;
}

a.site-link-gray:hover {
  color: #333;
  text-decoration: none;
}

.nav-item {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
}

.nav-link {
  text-decoration: none;
  color: #888888;
  line-height: 0.8rem;
  border-bottom: 0px;
}

.nav-link:hover {
  color: #333333;
  text-decoration: none;
  border-bottom: 0px;
}

.navbar-brand {
  color: #555;
}

.navbar-brand:hover {
  color: #333;  
}

.navbar-toggler {
  border-style: none;
}

.tag {
  color: #a9a998;
  background-color: #e9e9de;
  padding: 4px 8px;
  border-radius: 12px;
}

.red-bar {
  color: #de5a44;
}

.btn-site-red {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  --bs-btn-color: #e16a56;
  --bs-btn-border-width: 3px;
  --bs-btn-border-color: #e78b7c;
  --bs-btn-hover-color: #de5a44;
  --bs-btn-hover-bg: #e78b7c;
  --bs-btn-hover-border-color: #e78b7c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #e16a56;
  --bs-btn-active-bg: #de5a44;
  --bs-btn-active-border-color: #e78b7c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-gradient: none;
}

.btn-site-gray {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  --bs-btn-color: #91918e;
  --bs-btn-border-width: 3px;
  --bs-btn-border-color: #91918e;
  --bs-btn-hover-color: #666666;
  --bs-btn-hover-bg: #c2c2bd;
  --bs-btn-hover-border-color: #888888;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #aaaaa5;
  --bs-btn-active-bg: #c2c2bd;
  --bs-btn-active-border-color: #aaaaa5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-border-width: 3px;
}

.btn-hover-icon {
  position: relative;
}

.btn-hover-icon .text,
.btn-hover-icon .icon {
  transition: opacity 0.3s ease;
}

.btn-hover-icon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
}

.btn-hover-icon:hover .text {
  opacity: 0;
}

.btn-hover-icon:hover .icon {
  opacity: 1;
}

.btn-tag {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 1rem;
  color: #555555;
  background-color: #e2ddd7;
  --bs-btn-color: #555555;
  --bs-btn-border-color: #d4d4bf;
  --bs-btn-hover-color: #333333;
  --bs-btn-hover-bg: #d4d4bf;
  --bs-btn-hover-border-color: #d4d4bf;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #555555;
  --bs-btn-active-bg: #d4d4bf;
  --bs-btn-active-border-color: #d4d4bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-gradient: none;
}

.panel {
  background-color: #e2ddd7;
}

.custom-panel {
  position: relative;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  
}

.custom-panel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: background 0.3s ease;
}

.custom-panel-overlay i,
.custom-panel-overlay h3,
.custom-panel-overlay p,
.custom-panel-overlay a {
  text-align: left;
}

.custom-panel:hover .custom-panel-overlay {
  background: rgba(222, 90, 68, 0.6); /* Red overlay on hover */
}

.card.card-index {
/*  background-color: #54544c;*/
  background-color: #3f3f39;
}

/* Custom styles for book cover images */
.book-cover-image {
    width: 80%; /* Default width for larger screens */
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
    .book-cover-image {
        width: 50%; /* Smaller width for mobile screens */
    }
}

.book-cover {
  width: 100%;
  padding-top: 150%; /* Adjust based on the aspect ratio of your book cover */
  background-size: cover;
  background-position: center;
  display: block;
  box-shadow: 3px 3px 15px rgba(0,0,0,0.3);
  margin: 20px auto;
  position: relative;
  overflow: hidden;
  transition: transform 0.5s ease, box-shadow 0.5s ease; /* Smooth transition for hover effects */
}

.book-cover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 2%; /* Positioned close to the left edge but not on it, adjust as needed */
  height: 100%;
  width: 6px; /* Thin strip to simulate the shadow */
  background: rgba(0,0,0,0.10); /* Subtle shadow color */
  box-shadow: inset 3px 0 3px -3px rgba(0,0,0,0.5); /* Inset shadow for the groove effect */
  transition: transform 0.5s ease, left 0.5s ease; /* Smooth transition for hover effects */
  opacity: 1; /* Always visible */
}

.book-cover:hover {
  transform: perspective(1000px) rotateY(-10deg); /* More dramatic perspective and rotation on hover */
  box-shadow: 8px 8px 20px rgba(0,0,0,0.5);
}